<template>
    <div>
        <v-card>
            <v-card-title class="title">
                <v-spacer></v-spacer>
                <print-buttons :downloadUrl="downlaodUrl" :pdf="true" :excel="true"
                               v-if="form.items.data.length > 0"></print-buttons>
            </v-card-title>

            <v-card-title class="title">
                <v-flex xs3>
                    <v-select :loading="loadingGrade" :disabled="grades.length<1"
                              @change="getSections()"
                              :items="grades"
                              class="pa-0" label="Select grade"
                              outlined dense
                              v-model="grade"/>
                </v-flex>
                <v-flex xs3>
                    <v-select :loading="loadingSection" :disabled="sections.length<1"
                              :items="sections"
                              class="pa-0"
                              label="Select section"
                              outlined dense
                              v-model="section"/>
                </v-flex>
                <v-flex xs3>
                    <v-calendar-field
                            id-val="assignment_date"
                            v-model="assignment_date"
                            label="Assignment Date">

                    </v-calendar-field>


<!--                    <v-text-field autocomplete="off"-->
<!--                                  :mask="'####-##-##'"-->
<!--                                  :rules="dateValidation"-->
<!--                                  label="Assignment Date*"-->
<!--                                  required class="pa-0 pl-2"-->
<!--                                  outlined dense-->
<!--                                  v-model="assignment_date" name="assignment_date"-->
<!--                                  :disabled="!section"-->
<!--                    />-->
                </v-flex>
                <v-flex xs2>
                    <v-btn color="primary" class="mt-n8" outlined @click="get()" :disabled="!section || !assignment_date">Search</v-btn>
                </v-flex>
            </v-card-title>
        </v-card>
        <!-- <v-container> -->
            <template>
                <v-layout row wrap>
                    <v-flex xs12>
                        <v-card>
                            <v-card-title>
                                <v-spacer></v-spacer>
                            </v-card-title>
                            <v-data-table
                                    :headers="headers"
                                    :items="form.items.data"
                                    :options.sync="pagination"
                                    :server-items-length="form.items.meta.total"
                                    :loading="form.loading">
                                <template  v-slot:item="{index, item}">
                                    <tr>
                                        <td>{{ index + form.items.meta.from }}</td>
                                        <td class="text-xs-left">{{ item.name }}</td>
                                        <td class="text-xs-left">{{ item.title }}</td>
                                        <td class="text-xs-left">{{ item.submission_date }}</td>
                                        <td class="text-xs-left">{{ item.teacher }}
                                            <p v-if="item.primary_mobile">({{item.primary_mobile}})</p>
                                        </td>
                                        <td class="text-xs-left">
                                            <span class="completed bgreen">{{ item.complete }}</span>
                                            <span class="completed byellow">{{ item.partialcomplete }}</span>
                                            <span class="completed bred">{{ item.notdone }}</span>
                                        <td class="text-xs-left">
                                            {{ item.notchecked }}
                                        </td>
                                    </tr>
                                </template>
                            </v-data-table>
                        </v-card>
                    </v-flex>
                </v-layout>
            </template>
        <!-- </v-container> -->
    </div>
</template>
<script>
    import {mapState} from "vuex";
    import Form from '@/library/Form'
    import {getPropertyFromArrayObject, nepNumberToEng, engNumberToNep} from '@/library/helpers'
    import {validateDate} from "../../../../../library/helpers";


    const NepaliDate = require('nepali-date');
    export default {
        data: () => ({
            form: new Form({}, '/api/report/academic/assignment/check-status'),
            grades: [],
            tab: null,
            downlaodUrl: '',
            grade: '',
            section: '',
            assignment_date: new NepaliDate(new Date()).format('YYYY-MM-DD'),
            sections: [],
            model: '',
            calendar: [],
            loadingGrade: false,
            loadingSection: false,

            headers: [
                {text: '#', align: 'center', value: 'id', width: 50, sortable: false},
                {text: 'Name', align: 'left', value: 'name'},
                {text: 'Title', align: 'left', value: 'title', sortable: false, width: 200},
                {text: 'Submission Date', align: 'left', value: 'submission_date', sortable: false},
                {text: 'Teacher', align: 'left', value: 'teacher', sortable: false},
                {text: 'Completed/Partial/Not Done', align: 'left', value: 'completed', sortable: false},
                // {text: 'Partial Complete', align: 'left', value: 'partial_complete', sortable: false},
                // {text: 'Not Done', align: 'left', value: 'not_done', sortable: false},
                {text: 'Not Checked', align: 'left', value: 'not_checked', sortable: false},
                // {text: 'Action', align: 'right', value: 'action', sortable: false, width: 200}
            ],
            dateValidation: [
                (v) => validateDate(v) || 'Invalid Date Format',
            ],
            days: [],
            years: [{value: '2075', text: '2075'}, {value: '2076', text: '2076'}],

            data: [],
            pagination: {
                rowsPerPage: 10,
            },
        }),

        computed: {
            ...mapState(['batch']),
        },
        mounted() {
            this.getGrades();
        },
        watch: {
            'grade': function () {
                this.section = '';
                this.form.items.data = [];
            },
            'section': function (v) {
                this.form.items.data = [];
            },
            'assignment_date': function () {
                this.form.items.data = [];
            }
        },
        methods: {
            nepNumberToEng,
            queryString() {
                let json = this.pagination;
                return '?' +
                    Object.keys(json).map(function (key) {
                        if (![null, undefined].includes(json[key]))
                            return encodeURIComponent(key) + '=' + encodeURIComponent(json[key]);
                    }).join('&') + '&gradeId=' + this.grade + '&sectionId=' + this.section + '&assignmentDate=' + this.assignment_date.dateForm();
            },


            get(params) {
                let query = [null, undefined].includes(params) ? this.queryString() : params;
                this.form.get(null, query).then(({data}) => {
                    this.downlaodUrl = data.download_url;
                });

            },
            viewAssignemnt(item) {
                this.$router.push({
                    name: 'assignment-detail',
                    params: {
                        assignmentId: item.id
                    }
                })
            },


            getGrades() {
                this.sections = [];
                this.section = '';
                this.loadingGrade = true;
                this.$rest.get('/api/grades?rowsPerPage=20&sortBy=rank&descending=false&slim=true').then(({data}) => {
                    this.grades = data.data.map(item => {
                        return {value: item.id, text: item.name}
                    });
                }).finally(err => {
                    this.loadingGrade = false;
                })
            },
            getSections() {
                this.section = '';
                this.sections = [];
                this.loadingSection = true;
                this.$rest.get('/api/section?batch=' + this.batch.id + '&grade=' + this.grade).then(({data}) => {
                    this.sections = data.data.map(item => {
                        return {value: item.id, text: item.name}
                    });
                }).finally(() => {
                    this.loadingSection = false;
                })
            },

        }
    }
</script>
<style lang="scss">
    .completed {
        border-radius: 11px;
        padding-left: 10px;
        padding-right: 10px;
        padding-top: 5px;
        padding-bottom: 5px;
        color: white;
        margin: 5px;
    }
    .bred {
        background: #ff5252;
    }
    .bgreen{
        background: #4caf50;
    }
    .byellow{
        background: #ff9800;
    }
</style>